// Fix for IE10 and Windows Mobile
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style');
    msViewportStyle.appendChild(
        document.createTextNode(
            '@-ms-viewport{width:auto!important}'
        )
    );
    document.querySelector('head').appendChild(msViewportStyle);
}

// Animated scrolling
$('a[rel="scroll"]').click(function (event) {
    event.preventDefault();
    var scrolloffset = $('#sticky-header').outerHeight() + 75;
    var hash = $(this).attr('href');
    $("html, body").animate({scrollTop: $(hash).offset().top - scrolloffset}, 1200);
});

$(document).ready(function () {
    // Tooltip
    $('[data-toggle="tooltip"]').tooltip();

    // FitVids
    if ($.isFunction($.fn.fitVids)) {
        $('.fitvids').fitVids();
    }

    // Fancybox
    $(".fancybox").fancybox({
        'loop': false,
        helpers: {
            title: {
                type: 'inside'
            }
        }
    });

    // Mobile navtoggle
    $('.navtoggle-subnav').click(function(){
        $('.subnav-items').slideToggle();
        $(this).find('.fa').toggleClass('fa-chevron-down fa-chevron-up');
    });
    $('.navtoggle-subnav .text-label').text($('.subnav-items li.active a').html());

    // Mobilenav
    $("#mobile-navtoggle").click(function() {
        $('#content').toggleClass('mobilenav-push');
        $('#mobiletop').toggleClass('open');
        $('nav#mobile').toggleClass('open');
        $(this).find('.icon').toggleClass('fa-bars fa-times');
        $('html').toggleClass('lock-scroll');
    });

    $('form.validate').validate();
});

// Sticky header
function stickyHeader() {
    var scrollTop = $(window).scrollTop();
    var triggerHeight = $('nav#main').position().top + $('nav#main').outerHeight(true);
    var stickyHeaderElement = $('#sticky-header');
    if (scrollTop > triggerHeight) {
        stickyHeaderElement.slideDown();
    } else {
        stickyHeaderElement.slideUp();
    }
}

// Scroll functions
$(window).scroll(stickyHeader);
